import Wrapper from "../wrappers/Banner"

const Banner = ({txt, img, align}) => {
  return (
    <Wrapper align={align}>
        <img 
            src={img} 
            alt=''
        />
        <h1>{txt}</h1>
    </Wrapper>
  )
}

export default Banner