import styled from "styled-components";

const Wrapper = styled.section`
    p{
        margin-top: 1rem;
        font-size: 1.25rem;
    }
    .sending-info{
        font-weight: bold;
        color: ${({success}) => success ? '#119118' : '#87210f'};
        text-align: center;
    }
    .contact-info{
        display: flex;
        justify-content: center;
        text-align: center;
        padding: 2rem;
        border-bottom: 1px solid var(--grey-100);
    }
    .form-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 2rem;
    }
    .form-container input{
        height: 2.5rem;
        padding: 0.5rem;
    }
    .form-basic{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 40rem;
    }
    .terms-container {
        display: flex;
        align-items: center;

    }
    .terms-container p {
        margin-left: .5rem;
        margin-bottom: 0;
        margin-top: 0;
    }
    .btns-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .btn {
        margin: 0.5rem 0;
    }
    .wedding-container input {
        width: 100%;
    }
    .wedding-places {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 1rem;
    }
    .wedding-info {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 1rem;
    }
    .btn-submit {
        background-color: var(--primary-100);
        color: var(--white);
        margin: 0;
        height: 2.5rem;
    }
    .active {
        background-color: var(--primary-100);
        color: var(--white);
        border: none;
    }
    small {
        color: var(--red-dark);
        font-weight: 500;
    }
    .hide{
        display: none;
    }
    @media (min-width: 640px) {
      .wedding-places {
        grid-template-columns: 1fr 1fr;
      }
      .wedding-info {
        grid-template-columns: 3fr 1fr;
      }  
      .btns-container {
        flex-wrap: nowrap;
      }
      .btn {
        margin: 0.5rem 0.5rem;
        }
        .btn-submit {
            margin: 0;
        }
    }
`

export default Wrapper