import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar, Sidebar, SmallNavbar } from '../components';
import arrowUp from '../images/arrow-up.svg'

const SharedLayot = () => {
  const [showSidebar, setShowSidebar] = useState(false)
  const [showNavTopBtn, setShowNavTopBtn] = useState(false)

  const handleSidebar = () => {
    setShowSidebar(!showSidebar)
  }
  const navTop = () => {
    window.scrollTo(0, 0)
  }
  
  useEffect(() => {
    const handleShowNavTopBtn = () => {
      if(window.scrollY === 0 ) {
        setShowNavTopBtn(false)
      } else {
        setShowNavTopBtn(true)
      }
    }
    window.addEventListener('scroll', handleShowNavTopBtn)
    return () => {
      window.removeEventListener('scroll', handleShowNavTopBtn)
    }
  }, [])
  return (
    <main className='main'>
        <Navbar />
        <SmallNavbar handleSidebar={handleSidebar}/>
        <Sidebar show={showSidebar} handleSidebar={handleSidebar}/>
        <button 
          className={showNavTopBtn ? 'btn-nav-top show-nav-top-btn' : 'btn-nav-top'}
          onClick={navTop}
        >
          <img src={arrowUp} alt='Up'/>
        </button>
        <div className='content'>
            <Outlet />
        </div>
    </main>
  )
}

export default SharedLayot