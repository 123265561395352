import {NavLink} from 'react-router-dom';
import links from '../utils/links';

const NavLinks = () => {
  return (
    <div className="nav-links">
        {links.map((link) => {
            const {id, text, path, icon} = link

            return ( 
                <NavLink
                    to={path}
                    key={id} 
                >
                    {icon && icon}
                    <div className='nav-txt'>{text}</div>
                    <div className='line-below-txt'></div>
                </NavLink>
            )
        })}
    </div>
  )
}

export default NavLinks