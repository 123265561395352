import Wrapper from "../wrappers/ContactRedirect"
import {NavLink} from 'react-router-dom';

const ContactRedirect = ({img}) => {
  return (
    <Wrapper>
        <img 
            src={img}
            alt=""
        />
        <div className="contact-box">
            <h2>PODOBA CI SIĘ TO CO ROBIĘ?</h2>
            <NavLink 
              to='/contact'
              className='btn btn-block'
            >
              Zapytaj o termin
            </NavLink>
            <NavLink 
              to='/offer'
              className='btn btn-ghost'
            >
              Zobacz ofertę
            </NavLink>
        </div>
    </Wrapper>
  )
}

export default ContactRedirect