import styled from "styled-components";

const Wrapper = styled.div`
    .aboutme-container{
        display: block;
        grid-template-columns: 1fr 1fr;
        column-gap: 8%;
        margin-bottom: 7%; 
    }
    p{
        margin-bottom: 1rem;
        max-width: none;
        text-align: justify;
        text-justify: inter-word;
    } 
    img {
        width: 100%;
        max-height: 45rem;
        object-fit: cover;
    }
    
    @media (min-width: 840px) { 
        .aboutme-container {
            display: grid;
        }
        .column-reverse{
            grid-template-columns: repeat(2, 1fr);
            grid-auto-flow: dense;
            direction: rtl;
        }
        .column-reverse h3, .column-reverse p {
            direction: ltr;
            text-align: right;
            text-align: justify;
            text-justify: inter-word;
        }
    }
`

export default Wrapper