import styled from "styled-components";

const Wrapper = styled.nav`
    position: absolute;
    z-index: 99;
    top: 0;
    width: 100%;
    padding: 1rem 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.67) 0%, rgba(0, 0, 0, 0) 100%);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .logo {
    }
    .menu-icon {
        height: 2.5rem;
        filter: invert(100%) sepia(0%) saturate(7445%) hue-rotate(40deg) brightness(122%) contrast(97%);
    }
    .navbar-container{
        width: 55%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 2rem;
    }
    @media (min-width: 839px) {
        display: none;
    }
`

export default Wrapper