import styled from "styled-components";

const Wrapper = styled.section`
    margin: 0 5%;
    .head-txt{
        margin-top: 3rem;
        min-width: 100%;
        text-align: center;
    }
    .aboutme-container {
        margin-top: 8%;
    }
    .cards-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        row-gap: 1.5rem;
    } 
    .btn {
        margin-bottom: 1rem;
    }
    @media (min-width: 840px) {
        .btn {
            margin-bottom: 0;
        }
    }
    @media (min-width: 996px) {
        .cards-container {
            flex-direction: row;
        }
    }
    @media (min-width: 1100px) {
        margin: 0 10%;
    }
`

export default Wrapper