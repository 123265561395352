import styled from 'styled-components';

const Wrapper = styled.section`
    main{
        margin: 3%;
    }     
    .portfolio-container{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1rem;
        max-width: 100%;
        margin: 2rem 0;
        position: relative;
        text-align: center;
    }
    .info-txt {
        text-align: center;
        margin: 10% 0;
    }
    .small-img{
        max-width: 100%;
        height: 100%; 
        object-fit: cover;
    }
    @media (min-width: 840px) {
        main{
            margin: 10%;
        }  
    }
`

export default Wrapper