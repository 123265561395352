import styled from "styled-components";

const Wrapper = styled.nav`
    width: 100%;
    text-decoration: none;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
    background-color: #E7E1DCA8;
    a {
        padding: 0.75rem 0.6rem;
        color: black;
        text-align: center;
    }
    a:hover{
        background-color: var(--backgroundColor);
    }
    .active{
        background-color: var(--backgroundColor);
    }   
    @media (min-width: 840px) {
        padding: 0 10%;
    }
`

export default Wrapper