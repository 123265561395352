import styled from "styled-components";

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    max-width: 20rem;
    background-color: rgba(0, 0, 0, 0.78);
    z-index: 100;
    margin-right: ${props => props.show ? '0' : '-20rem'};
    transition: margin-right 1s ease-in-out;
    .close-icon{
        filter: invert(100%) sepia(0%) saturate(7445%) hue-rotate(40deg) brightness(122%) contrast(97%);
        height: 2.5rem;
        float: right;
        margin-top: 2rem;
        margin-right: 2rem;
    }
    .links {
        display: flex;
        flex-direction: column;
        margin: 6rem 0 0 3rem;
    }
    .links a {
        color: var(--white);
        font-size: 1.3rem;
        margin-bottom: 1rem;
    }
`

export default Wrapper