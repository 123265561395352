import React from 'react'
import {NavLink} from 'react-router-dom';
import links from '../utils/links';
import Wrapper from '../wrappers/Sidebar'
import closeIcon from '../images/close-icon.svg'

const Sidebar = ({show, handleSidebar}) => {
  return (
    <Wrapper show={show}>
        <img src={closeIcon} alt='' className='close-icon' onClick={handleSidebar}/>
        <div className='links'>
              <NavLink
                to='/'
                onClick={handleSidebar}
              >
                STRONA GŁÓWNA
              </NavLink>
          {links && links.map((e, index) => {
            if (index === 2 ) return <div key={index}/>
            return (
              <NavLink
                to={e.path}
                key={index}
                onClick={handleSidebar}
              >
                {e.text}
              </NavLink>
            )
          })}
        </div>
    </Wrapper>
  )
}

export default Sidebar