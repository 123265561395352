import Wrapper from "../wrappers/Footer"
import instagramIcon from '../images/instagram-icon.svg'
import facebookIcon from '../images/facebook-icon.svg'
import footerImgs from '../images/footer'
import { NavLink } from "react-router-dom"

const Footer = () => {

    const handleClick = (e) => {
        const name = e.target.name

        if (name === 'facebook') {
            window.open('https://www.facebook.com/KUBASTEPEKPIC')
        } else if (name === 'instagram') {
            window.open('https://www.instagram.com/kubastepekpic/?hl=pl')
        }
    }
  return (
    <Wrapper>
        <div className="contact-container">
            <p>Jakub Stepek</p> 
            <p>kubastepekpic@gmail.com</p>
            <p>698 131 067</p>
        </div>
        <div className="social-media-container">
            <div 
                className="insta-container"
                
            >
                <img 
                    src={instagramIcon}
                    alt=''
                    name='instagram'
                    onClick={handleClick}
                />
                <p>Zaobserwuj mnie na instagramie</p>
                <a href='https://www.instagram.com/kubastepekpic/?hl=pl'>instagram.com/kubastepekpic/</a>
            </div>
            <div 
                className="insta-container"    
            >
                <img 
                    src={facebookIcon}
                    alt=''
                    name='facebook'
                    onClick={handleClick}
                />
                <p>Polub moje konto na facebooku</p>
                <a href='https://www.facebook.com/KUBASTEPEKPIC'>facebook.com/KUBASTEPEKPIC</a>
            </div>
        </div>
        <div className="images-container">
            {footerImgs && footerImgs.map((e, index) => {
                return (
                    <img src={e} key={index} alt=''/>
                )
            })}
        </div>
        <div className="info-container">
            <p>2023 Fotograf ślubny - Jakub Stepek. Wszystkie prawa zastrzeżone</p>
            <NavLink
                to='/terms-and-privacy'
            >
                <p>Regulamin i polityka prywatności</p>
            </NavLink>
        </div>
    </Wrapper>
  )
}

export default Footer