import React, { useEffect, useState } from 'react'
import Wrapper from '../wrappers/Carousel'
import { NavLink } from 'react-router-dom'

const Carousel = ({slides}) => {
    const [current, setCurrent] = useState(0)

    useEffect(() => {
        const slideInterval = setInterval(() => {
            setCurrent(current => current < slides.length -1 ? current +1 : 0)
        }, 5000)

        return () =>  {clearInterval(slideInterval)}
        /* eslint-disable */
    }, [])

  return ( 
    <Wrapper>
        <div 
            className='carousel-inner'
            style={{transform: `translateX(${-current * 100}%)`}}
        >
            {slides && slides.map((e, index) => {
                return (
                    <div key={index} className='carousel-item'>
                        <img src={e}  alt='' className='carousel-img'/>
                    </div>
                )
            })}
        </div>
        <div className='session-name'>
            <NavLink to='/portfolio/wedding'>
                <h2 className={current === 0 ? 'current' : 'session-name-txt'}>FOTOGRAFIA ŚLUBNA</h2>
            </NavLink>
            <NavLink to='/portfolio/love'>
                <h2 className={current === 1 ? 'current' : 'session-name-txt'}>SESJE MIŁOSNE</h2>
            </NavLink>
            <NavLink to='/portfolio/family'>
                <h2 className={current === 2 ? 'current' : 'session-name-txt'}>SESJE RODZINNE</h2>
            </NavLink>
        </div>
    </Wrapper>
  )
}

export default Carousel