import s1 from './s-1.webp'
import s2 from './s-2.webp'
import s3 from './s-3.webp'
import s4 from './s-4.webp'
import s5 from './s-5.webp'
import s6 from './s-6.webp'
import s7 from './s-7.webp'
import s8 from './s-8.webp'
import s9 from './s-9.webp'
import s10 from './s-10.webp'
import s11 from './s-11.webp'
import s12 from './s-12.webp'
import s13 from './s-13.webp'
import s14 from './s-14.webp'
import s15 from './s-15.webp'
import s16 from './s-16.webp'
import s17 from './s-17.webp'
import s18 from './s-18.webp'
import s19 from './s-19.webp'
import s20 from './s-20.webp'
import s21 from './s-21.webp'
import s22 from './s-22.webp'
import s23 from './s-23.webp'
import s24 from './s-24.webp'
import s25 from './s-25.webp'
import s26 from './s-26.webp'
import s27 from './s-27.webp'
import s28 from './s-28.webp'
import s29 from './s-29.webp'
import s30 from './s-30.webp'
import s31 from './s-31.webp'
import s32 from './s-32.webp'
import s33 from './s-33.webp'
import s34 from './s-34.webp'
import s35 from './s-35.webp'
import s36 from './s-36.webp'
import s37 from './s-37.webp'
import s38 from './s-38.webp'
import s39 from './s-39.webp'
import s40 from './s-40.webp'
import s41 from './s-41.webp'
import s42 from './s-42.webp'
import s43 from './s-43.webp'
import s44 from './s-44.webp'
import s45 from './s-45.webp'
import s46 from './s-46.webp'
import s47 from './s-47.webp'
import s48 from './s-48.webp'
import s49 from './s-49.webp'
import s50 from './s-50.webp'
import s51 from './s-51.webp'
import s52 from './s-52.webp'
import s53 from './s-53.webp'
import s54 from './s-54.webp'
import s55 from './s-55.webp'
import s56 from './s-56.webp'
import s57 from './s-57.webp'
import s58 from './s-58.webp'
import s59 from './s-59.webp'
import s60 from './s-60.webp'
import s61 from './s-61.webp'
import s62 from './s-62.webp'
import s63 from './s-63.webp'
import s64 from './s-64.webp'
import s65 from './s-65.webp'
import s66 from './s-66.webp'
import s67 from './s-67.webp'
import s68 from './s-68.webp'
import s69 from './s-69.webp'
import s70 from './s-70.webp'
import s71 from './s-71.webp'
import s72 from './s-72.webp'
import s73 from './s-73.webp'
import s74 from './s-74.webp'
import s75 from './s-75.webp'
import s76 from './s-76.webp'
import s77 from './s-77.webp'
import s78 from './s-78.webp'
import s79 from './s-79.webp'
import s80 from './s-80.webp'
import s81 from './s-81.webp'
import s82 from './s-82.webp'
import s83 from './s-83.webp'
import s84 from './s-84.webp'
import s85 from './s-85.webp'
import s86 from './s-86.webp'
import s87 from './s-87.webp'
import s88 from './s-88.webp'
import s89 from './s-89.webp'
import s90 from './s-90.webp'
import s91 from './s-91.webp'
import s92 from './s-92.webp'
import s93 from './s-93.webp'
import s94 from './s-94.webp'
import s95 from './s-95.webp'
import s96 from './s-96.webp'
import s97 from './s-97.webp'
import s98 from './s-98.webp'
import s99 from './s-99.webp'
import s100 from './s-100.webp'
import s101 from './s-101.webp'
import s102 from './s-102.webp'
import s103 from './s-103.webp'
import s104 from './s-104.webp'
import s105 from './s-105.webp'
import s106 from './s-106.webp'
import s107 from './s-107.webp'
import s108 from './s-108.webp'
import s109 from './s-109.webp'
import s110 from './s-110.webp'
import s111 from './s-111.webp'
import s112 from './s-112.webp'
import s113 from './s-113.webp'
import s114 from './s-114.webp'
import s115 from './s-115.webp' 

const weddingImages = [
    s1, s2, s3, s4, s5, s9, s7, s8, s6, s10, s11, s12, s13, s14, 
    s15, s16, s17, s18, s19, s20, s21, s22, s23, s24, s25, s32, 
    s27, s28, s29, s30, s31, s26, s33, s34, s35, s36, s37, s38,
    s39, s44, s41, s42, s67, s40, s45, s46, s47, s48, s49, s50, s51,
    s52, s53, s54, s55, s56, s57, s58, s59, s60, s61, s62, s65, s64, s63,
    s66, s43, s68, s69, s70, s71, s72, s73, s74, s75, s76, s77, s78, s79,
    s80, s81, s82, s83, s85, s84, s86, s87, s88, s89, s90, s91, s92, s93,
    s94, s95, s96, s97, s98, s102, s100, s99, s101, s105, s104, s103, s106,
    s107, s108, s109, s110, s111, s112, s113, s114, s115
]

export default weddingImages
