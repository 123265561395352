import Wrapper from "../wrappers/Offer"
import { Banner, AboutMe, Card, Footer } from '../components'
import bannerOffer from '../images/banner-offer.webp'
import offerWedding from '../images/offer-wedding.webp'
import offerLove from '../images/offer-love.webp'
import offerFamily from '../images/offer-family.webp'

const Offer = () => {
  return (
    <div>
      <Banner 
        img={bannerOffer}
        txt='OFERTA'
        align='top'
      />
      <Wrapper> 
        <p className="head-txt">Życie pokazało mi jaką wartość mają zdjęcia. Każdy etap z mojego dzieciństwa był fotografowany i wywoływany do albumów rodzinnych. Każdą sesję, każdy reportaż, będą kiedyś oglądały pokolenia… Zachowaj w kadrze swoje życie. Czas z drugą połówką, czas z rodziną nie wróci.</p>
        <AboutMe 
          h1='FOTOGRAFIA ŚLUBNA'
          p1='Ślub to jeden z najważniejszych momentów w Waszym życiu. Będę zaszczycony móc towarzyszyć wam w tym wyjątkowym dniu. Każdą parę traktuję indywidualnie, dlatego ofertę wysyłam bezpośrednio na adres e-mail. Wystarczy zapytać o moją dostępność na termin Waszego ślubu uzupełniając formularz kontaktowy, aby po kilku chwilach otrzymać ofertę. Cena repotraży różni się w zależności od odległości, preferencji, roku i wybranego pakietu od 3 do 5 tyś zł.'
          img={offerWedding}
          link='/portfolio/wedding'
          linkTxt='Obejrzyj przykładowe sesje'

        />
        <AboutMe 
          h1='SESJE MIŁOSNE'
          p1='Do sesji miłosnych czuję największą sympatię. Sesja miłosna ma być czasem dla Was, chwilą czułości, bliskości. Najpiękniejsze kadry miłosne, które wykonałem zaczynały się od słów „my nie umiemy pozować”. Zadbam o stworzenie takiej atmosfery, która pozwoli wam się otworzyć i spędzić miło czas. Zdjęcia są produktem ubocznym. Wszystko jest dla Was. Po mojej stronie stoi pokierowanie Wami i zauważenie potencjału, w tym co na pierwszy rzut oka jest bardzo zwyczajne.'
          img={offerLove}
          columnReverse={true}
          link='/portfolio/love'
          linkTxt='Obejrzyj przykładowe sesje'
        />
        <div className="cards-container">
          <Card 
            h1='PAKIET PODSTAWOWY'
            props={['1 h sesji', '10 ujęć w postaci elektronicznej', '10 odbitek premium 13x18 cm', 'Każde dodatkowe ujęcie 35 zł', 'Cena obowiązuje do 15km od Tarnowa', 'Powyżej 15km +1zł za każdy 1km', 'Realizacja 14 dni roboczych', 'Inwestujesz: 480 złotych']}
          />
          <Card 
            h1='PAKIET PREMIUM'
            props={['1 h sesji', '21 ujęć w postaci elektronicznej', '10 odbitek premium 13x18 cm', 'Każde dodatkowe ujęcie 30 zł', 'Cena obowiązuje do 15km od Tarnowa', 'Powyżej 15km +1zł za każdy 1km', 'Realizacja 14 dni roboczych', 'Inwestujesz: 770 złotych']}
          />
        </div>
        <AboutMe 
          h1='SESJE RODZINNE'
          p1='Sesja z udziałem rodziny to uwiecznienie momentów od narodzenia dziecka aż po zatrzymanie w kadrze relacji pomiędzy rodzicami, dziadkami, kuzynami. Jedno jest pewne, na pewno nie będzie sztywno! Sesja ma być dobrą zabawą dla dziecka! Ważną chwilą jest też czas oczekiwania na maluszka. Specjalnie z tej okazji przygotowałem pakiet DUO, dwóch sesji w jednym pakiecie (sesja ciążowa, sesja noworodkowa).'
          img={offerFamily}
          link='/portfolio/family'
          linkTxt='Obejrzyj przykładowe sesje'
        />
        <div className="cards-container">
          <Card 
            h1='PAKIET PODSTAWOWY'
            props={['1 h sesji', '10 ujęć w postaci elektronicznej', '10 odbitek premium 13x18 cm', 'Każde dodatkowe ujęcie 35 zł', 'Cena obowiązuje do 15km od Tarnowa', 'Powyżej 15km +1zł za każdy 1km', 'Realizacja 14 dni roboczych', 'Inwestujesz: 480 złotych']}
          />
          <Card 
            h1='PAKIET PREMIUM'
            props={['1 h sesji', '21 ujęć w postaci elektronicznej', '21 odbitek premium 13x18 cm', 'Każde dodatkowe ujęcie 30 zł', 'Cena obowiązuje do 15km od Tarnowa', 'Powyżej 15km +1zł za każdy 1km', 'Realizacja 14 dni roboczych', 'Inwestujesz: 770 złotych']}
          />
          <Card 
            h1='PAKIET DUO'
            props={['Dwie sesje jednogodzinne', '17 elektronicznych ujęć z sesji ciążowej', '17 elektronicznych ujęć z sesji ciążowej', 'Każde dodatkowe ujęcie 30 zł', 'Cena obowiązuje do 15km od Tarnowa', 'Powyżej 15km +1zł za każdy 1km', 'Realizacja 14 dni roboczych', 'Inwestujesz: 1300 złotych']}
          />
        </div>
      </Wrapper>
      <Footer />
    </div>
  ) 
}

export default Offer