import styled from 'styled-components'

const Wrapper = styled.section`
    position: relative;
    display: flex;
    justify-content: center;
    img{
        max-width: 100%;
        object-fit: cover;
        width: 100%;
        height: 90vh;
    }
    .contact-box{
        display: flex;
        flex-direction: column;
        position: absolute;
        margin: 0 auto;
        background-color: var(--backgroundColor);
        padding: 2rem;
        max-width: 25rem;
        text-align: center;
    }
    h2{
        margin-bottom: 2rem;
    }
    .btn {
        margin-bottom: 1rem;
    }
    @media (max-width: 839px) {
        .contact-box {
            bottom: 2rem;
            margin: 0 1rem;
        }
    }
    @media (min-width: 840px) {
        img{
            max-height: 40rem;
        }
        .contact-box{
            top: 6rem;
            left: 8rem;
        }
    }
`

export default Wrapper