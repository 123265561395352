import React, { useState } from 'react'
import Wrapper from '../wrappers/Opinions'
import quoteMark from '../images/quote-mark.svg'
import sylwiaPiotreOpinions from '../images/sylwia-i-piotrek-opinie.webp'
import dominikaWojtekOpinions from '../images/dominika-i-wojtek-opinie.webp'
import olaOpinions from '../images/ola-opinie.webp'
import kasiaSzymek from '../images/kasia-i-szymek-opinie.webp'
import arrowRight from '../images/arrow-right.svg'
import arrowLeft from '../images/arrow-left.svg'

const Opinions = () => {
  const [opinion, setOpinion] = useState(0)
  
  const opinionsArray = [
    { 
      img: sylwiaPiotreOpinions,
      txt:'„Kuba oglądam te zdjęcia jak szalona. Jestem totalnie zakochana. Nie wiem czy i to już mówiłam, ale jesteś fotografem, który wyciągnie piękne emocje z ludzi nawet gdy stać będą na baczność. Twoje fotografie chwytają głęboko za serce…”',
      name:'Sylwia & Piotrek'
    },
    {
      img: dominikaWojtekOpinions,
      txt: ' „Nasza sesja brzuszkowa była wspaniała. Zdjęcia zachwycają wszystkich. A teraz czekamy na sesję maluszka.”',
      name: 'Dominika & Wojtek'
    },
    {
      img: kasiaSzymek,
      txt: '“Jesteśmy pod wrażeniem efektów z sesji narzeczeńskiej. Zdjęcia plenerowe są wspaniałe, naturalne i przepełnione emocjami. Kuba podczas sesji zachowywał się bardzo profesjonalnie jednocześnie dbał o dobry nastrój i miłą atmofserę, dzięki czemu była to dla nas dobra zabawa. Fotografie będą stanowiły przepiękną pamiątknę na długie lata. Dziękujemy.”',
      name: 'Kasia & Szymon'
    },
    {
      img: olaOpinions,
      txt: '„Jestem zdania że od czasu do czasu warto się rozpieszczać i robić sobie jakieś prezenty. Dlatego właśnie z tego powodu zdecydowałam się na sesję u Kuby i zatrzymanie kilku pięknych kadrów na dłużej. ”',
      name: 'Ola'
    }
  ]
  const nextPic = () => {
    if(opinion < opinionsArray.length - 1){
      setOpinion(opinion + 1)
    } else {
      setOpinion(0)
    }
  }
  const prevPic = () => {
    if(opinion > 0 ) { 
      setOpinion(opinion - 1)
    } else {
      setOpinion(opinionsArray.length - 1)
    }
  }

  return (
    <Wrapper>
        <h1>OPINIE</h1>
        <div className='slider-container'>
          
          <div className='opinions-container'>
            <div 
              className="opinions-inner"
              style={{transform: `translateX(${-opinion * 100}%)`}}
            >
                {opinionsArray && opinionsArray.map((e, index) => {
                  return (
                    <div className='opinions-item' key={index}>
                      <div className="opinions-txt">
                        <img src={quoteMark} alt='' className='quote-img'/>
                        <p>
                          {e.txt}
                        </p>
                        <p>
                          {e.name}
                        </p>
                      </div>
                      <div className="opinions-img">
                        <img 
                            src={e.img}
                            alt=''
                        />
                      </div>
                    </div>
                  )
                })}
                
            </div>
          </div>
          
          <div className='arrows-container'>
          <img 
            src={arrowLeft} 
            alt='' 
            className='arrow arrow-left'
            onClick={prevPic}
          />
          <img 
            src={arrowRight} 
            alt=''
            className='arrow arrow-right'
            onClick={nextPic}
          /> 
          </div>     
        </div>
    </Wrapper>
  )
}

export default Opinions