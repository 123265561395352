import { NavLink } from 'react-router-dom'
import Wrapper from '../wrappers/PortfolioNavImg'
import arrow from '../images/arrow.svg'

const PortfolioNavImg = ({img, txt, path}) => {
  return (
    <Wrapper className="portfolio-img-container">
        {path &&
          <NavLink
            to={path}
          >
            <div className='img-container-box'>
              <img 
                className="portfolio-img"
                src={img && img ? img : ''}
                alt=''
              />
              {txt && <h4>{txt}</h4>}
              <span>
                <p>Wyświetl</p>
                <img src={arrow} alt=''/>
              </span>
            </div>
            
          </NavLink>
        }
        {!path && 
          <img 
          className="portfolio-img"
          src={img && img ? img : ''}
          alt=''
        />
        }
          {/*path && 
            <NavLink
              to={path} 
            >
              Wyświetl
            </NavLink> 
  */}
    </Wrapper>
  )
}

export default PortfolioNavImg