import styled from "styled-components";

const Wrapper = styled.section`
    margin: 0 auto;
    overflow: hidden;
    max-width: 100vw;
    position: relative;
    .carousel-inner {
        white-space: nowrap;
        transition: ease 1s;
    }
    .carousel-item  {
        display: inline-block;
        width: 100%;
    }
    .carousel-img {
        width: 100vw;
        max-height: 100vh;
        object-fit: cover;
    }
    .session-name {
        padding: 0 10% 4rem 10%;
        position: absolute;
        bottom: 0;
        display: flex;
        color: var(--white);
        justify-content: space-between;
        width: 100%;
        text-align: center;
        
    }
    a{
        color: var(--white);
    }
    .session-name-txt {
        opacity: 0.4;
        margin-right: 1rem;
    }
    .current {
        opacity: 1;
    }
    @media (max-width: 840px) { 
        .carousel-img {
            height: 70vh;
        }
        .session-name {
            display: none;
        }
    }
    @media (max-width: 996px) {
        .carousel-img {
            max-height: 70vh;
        }
    }
    @media (max-width: 1459px) {
        .carousel-img {
            height: 90vh;
        }
    }
`

export default Wrapper