import Wrapper from "../wrappers/PortfolioNavbar"
import { NavLink } from "react-router-dom"

const PortfolioNavbar = () => {
  
  return ( 
    <Wrapper>
        <NavLink to='/portfolio/wedding'>Fotografia ślubna</NavLink>
        <NavLink to='/portfolio/love'>Sesje miłosne</NavLink>
        <NavLink to='/portfolio/family'>Sesje rodzinne</NavLink>
    </Wrapper>
  )
}

export default PortfolioNavbar