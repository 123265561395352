import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { 
  About, 
  Contact,
  Landing, 
  Offer, 
  PortfolioWedding, 
  PortfolioLove,
  PortfolioFamily,
  TermsAndPrivacy,
  SharedLayot 
} from './pages';
import ScrollToTop from './utils/ScrollToTop'


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path='/' element={<SharedLayot />}>
              <Route index element={<Landing />}/>
              <Route path='about' element={<About />}/>
              <Route path='contact' element={<Contact />}/>
              <Route path='offer' element={<Offer />}/>
              <Route path='portfolio/wedding' element={<PortfolioWedding />}/>
              <Route path='portfolio/love' element={<PortfolioLove />}/>
              <Route path='portfolio/family' element={<PortfolioFamily />}/>
              <Route path='terms-and-privacy' element={<TermsAndPrivacy />}/>
            </Route>
            <Route path="*" element={<h3>Page not found</h3>} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
