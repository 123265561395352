import Wrapper from "../wrappers/Portfolio"
import { Banner, PortfolioNavbar, Footer, ContactRedirect } from '../components'
import bannerPortfolioLove from '../images/banner-portfolio-love.webp'
import contactRedirect from '../images/contact-redirect-2.webp'
import loveImages from "../images/portfolio/love"

const PortfolioLove = () => {
  return (
    <>
        <Wrapper>
        <Banner 
            txt='PORTFOLIO'
            img={bannerPortfolioLove}
            align='bottom'
        />
        <PortfolioNavbar />
        <section className="main-content">
            <h3>SESJE MIŁOSNE</h3>
            <p>Namiętność, czułe westchnięcia i dotyk. Zdjęciami opowiem historię Waszej miłości. W plenerze czy zaciszu waszego domu... Klimat sesji dostosowuje do Was i Waszego pomysłu. Podczas zdjęć robię wszystko co w mojej mocy, abyście czuli się swobodnie. Ta sesja to celebracja Waszej miłości, wspólna przygoda, z której powstanie wspaniała pamiątka na lata. Z pewnociá sprawdzi się jako prezent na Walentynki, prezent na rocznicę lub prezent ślubny.</p>
            <div className="pictures">
              {loveImages && loveImages.map((e, index) => {
                const setRow = index % 4
                return (
                  <img key={index} src={e} alt='' className={`row-${setRow}`}/>
                )
              })}
            </div>
        </section>
        </Wrapper>
        <ContactRedirect 
        img={contactRedirect}
        />
        <Footer />
    </>
  )
}

export default PortfolioLove