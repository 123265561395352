import styled from 'styled-components'

const Wrapper = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;

    .insta-container img {
        height: 2rem;
        width: 2rem;
    }
    .contact-container{ 
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .insta-container{
        display: flex;
        flex-direction: column;
        justify-content: center; 
        align-items: center;
    }
    p {
        margin: 1rem 0;
        text-align: center;
        max-width: 100%;
    }
    .info-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 0 2rem;
    }
    .terms-and-privacy {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: space-between;
        width: 100%;
    }
    .images-container {
        max-width: 100%;
        display: none;
    }
    .images-container img {
        height: 10rem;
        width: calc(100% / 7);
        object-fit: cover;
    }
    .social-media-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
    }
    .insta-container img:hover {
        cursor: pointer;
    }
    a {
        color: var(--black);
    }
    @media (min-width: 840px) {
        .images-container{
            display: flex;
        }
        .info-container {
            flex-direction: row;
        }
        .terms-and-privacy {
            grid-template-columns: 1fr 1fr;
        }
        .contact-container{ 
            flex-direction: row;
        }
        .social-media-container {
            flex-direction: row;
            gap: 4rem;
            justify-content: center;
        }
    }
`

export default Wrapper