import Wrapper from "../wrappers/Portfolio"
import { Banner, PortfolioNavbar, Footer, ContactRedirect } from '../components'
import bannerPortfolioFamily from '../images/banner-portfolio-family.webp'
import famiilyImages from "../images/portfolio/family"
import contactRedirect from '../images/contact-redirect-4.webp'

const PortfolioFamily = () => {
  return (
    <>
        <Wrapper>
        <Banner 
            txt='PORTFOLIO'
            img={bannerPortfolioFamily}
        />
        <PortfolioNavbar />
        <section className="main-content">
            <h3>SESJE RODZINNE</h3>
            <p>Sesja rodzinna to czas świetnej zabawy dla was i waszych pociech. Bądźcie sobą, bawcie, przytulajcie i wygłupiajcie się, a ja uwiecznię piękno codzienności i miłości. Chwile są ulotne, dlatego warto utrwalić ten czas, by móc wracać do niego zawsze, gdy macie na to ochotę. Zdjęcia z sesji mogą być wspaniałym ponadczasowym prezentem dla bliskich osób i pamiątką na długie lata.</p>
            <div className="pictures">
                {famiilyImages && famiilyImages.map((e, index) => {
                  const setRow = index % 4
                  return (
                    <img key={index} src={e} alt='' className={`row-${setRow}`}/>
                  )
                })}
            </div>
        </section>
        </Wrapper>
        <ContactRedirect 
            img={contactRedirect}
        />
        <Footer />
    </>
  )
}

export default PortfolioFamily