import r1 from './r-1.webp'
import r2 from './r-2.webp'
import r3 from './r-3.webp'
import r4 from './r-4.webp'
import r5 from './r-5.webp'
import r6 from './r-6.webp'
import r7 from './r-7.webp'
import r8 from './r-8.webp'
import r9 from './r-9.webp'
import r10 from './r-10.webp'
import r11 from './r-11.webp'
import r12 from './r-12.webp'
import r13 from './r-13.webp'
import r14 from './r-14.webp'
import r15 from './r-15.webp'
import r16 from './r-16.webp'
import r17 from './r-17.webp'
import r18 from './r-18.webp'
import r19 from './r-19.webp'
import r20 from './r-20.webp'
import r21 from './r-21.webp'
import r22 from './r-22.webp'
import r23 from './r-23.webp'
import r24 from './r-24.webp'
import r25 from './r-25.webp'
import r26 from './r-26.webp'
import r27 from './r-27.webp'
import r28 from './r-28.webp'
import r29 from './r-29.webp'
import r30 from './r-30.webp'
import r31 from './r-31.webp'
import r32 from './r-32.webp'
import r33 from './r-33.webp'
import r34 from './r-34.webp'
import r35 from './r-35.webp'
import r36 from './r-36.webp'
import r37 from './r-37.webp'
import r38 from './r-38.webp'
import r39 from './r-39.webp'
import r40 from './r-40.webp'
import r41 from './r-41.webp'
import r42 from './r-42.webp'
import r43 from './r-43.webp'
import r44 from './r-44.webp'
import r45 from './r-45.webp'
import r46 from './r-46.webp'
import r47 from './r-47.webp'
import r48 from './r-48.webp'
import r49 from './r-49.webp' 
import r50 from './r-50.webp'
import r51 from './r-51.webp'
import r52 from './r-52.webp'
import r53 from './r-53.webp'

const famiilyImages = [r1, r2, r3, r4, r5, r6, r7, r8,
    r9, r10, r11, r12, r13, r14, r15, r16, r17, r18, r19, r20,
    r21, r22, r23, r24, r25, r29, r27, r28, r26, r30, r31, r32,
    r33, r34, r35, r36, r42, r38, r39, r40, r41, r37, r43, r44,
    r45, r46, r47, r48, r49, r53, r51, r52, r50]

export default famiilyImages