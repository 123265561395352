import styled from "styled-components";

const Wrapper = styled.section`
    .main-content {
        margin: 4rem 10%;
        
        text-justify: inter-word;
    }
    p {
        max-width: 100%;
        margin-bottom: 1rem;
        width: 100%;
        
    }
`

export default Wrapper