import footer1 from './footer1.webp'
import footer2 from './footer2.webp'
import footer3 from './footer3.webp'
import footer4 from './footer4.webp'
import footer5 from './footer5.webp'
import footer6 from './footer6.webp'
import footer7 from './footer7.webp'

const footerImgs = [
    footer1,
    footer2,
    footer3,
    footer4,
    footer5,
    footer6,
    footer7
]

export default footerImgs