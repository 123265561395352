import Wrapper from "../wrappers/About"
import bannerAbout from '../images/banner-about.webp'
import kubaSmall3 from '../images/kuba-small-3.webp'
import kubaSmall4 from '../images/kuba-small-4.webp'
import grandfather from '../images/grandfather-small.webp'
import kubaSmall5 from '../images/kuba-small-5.webp'
import kubaSmall6 from '../images/kuba-small-6.webp'
import kubaSmall7 from '../images/kuba-small-7.webp'
import contactRedirect from '../images/contact-redirect-3.webp'
import { Banner, AboutMe,  ContactRedirect, Footer, Opinions } from "../components"

const About = () => {
  return (
    <Wrapper>
        <Banner 
          txt='O MNIE'
          img={bannerAbout}
          align='25% 25%'
        />
      <main>
        <AboutMe 
          h1='CZEŚĆ, JESTEM KUBA'
          p1='Z wykształcenia jestem fotografem z zakresu rejestracji, obróbki i publikacji obrazu, a swoją drogę fotograficzną zacząłem już w wczesnych latach szkolnych. Mam na swoim koncie wiele sesji i reportaży, podczas których uwieczniałem najważniejsze wydarzenia w życiu wielu wspaniałych ludzi.'
          p2='Natura jest bardzo bliska mojemu sercu, co często pokazuje w mojej twórczości. Jestem też ogromnym fanem fotografii analogowej, filmowej stylistyce i wszystkiego, co zawiera w sobie klimat vintage.'
          p3='Każda kolejna sesja i repotraż, są dla mnie okazją do pokazania moich umiejętności oraz wyrażenia emocji, które towarzyszą wam podczas sesji.'
          img={kubaSmall3}
        />
        <AboutMe 
          h1='3...2...1...PIC! czyli jak to się zaczęło'
          p1='Co najbardziej mnie poruszyło do działania i zaprzyjaźnienia się z aparatem na dłuższy czas? Starsi ludzie… Zwyczajny dzień na działce u pradziadków. Wystarczyło tak niewiele. Obok zdjęcie, od którego wszystko się zaczęł. Już wtedy wiedziałem, że to jest to. Takie zdjęcia najbardziej uświadamiają przemijanie i pokazują moc pamiątki.'
          img={grandfather}
          columnReverse={true}
        />
        <AboutMe 
          h1='OD PASJI DO STYLU ŻYCIA'
          p1='Fotografia jest moją największą pasją. Od wielu lat nieustannie rozwijam się w tym kierunku, biorąc udział w kursach i szkoleniach. Jednym z takich kroków w przód były warsztaty fotograficzne u Kasi Budzik i już planuję kolejne kursy, które ukształtują i uświadamią mi sporo kwestii na tej artystycznej drodze.'
          p2='Mówiąc o zainteresowaniach nie mogę pominąć tutaj muzyki – jednej z ważniejszych dziedzin w moim życiu. Śpiew, scena poruszają jeszcze mocniej strunę mojej wrażliwości i dopełniają niekiedy moje szalone i zakręcone życie.'
          p3='Jestem szczęśliwy, że mogę realizować się wykonując moją pasję na codzień. Mam nadzieję, że niebawem spotkamy się na sesji.'
          img={kubaSmall4}
        />
        <div className="portfolio-container">
          <img 
            className="small-img"
            src={kubaSmall5}
            alt=''
          />
          <img 
            className="small-img"
            src={kubaSmall6}
            alt=''
          />
          <img 
            className="small-img"
            src={kubaSmall7}
            alt=''
          />
        </div>
        <div className="info-txt">
          <h4>CHCESZ WIEDZIEĆ WIĘCEJ O MOJEJ PRACY I TWORZENIU?</h4>
          <h4>ZAPRASZAM CIĘ NA ROZMOWĘ I SESJĘ.</h4>
        </div>
        <Opinions />
      </main>
      <ContactRedirect 
        img={contactRedirect}
      />
      <Footer />
    </Wrapper>
  )
}

export default About 