import React from 'react'
import logo from '../logo.svg'

const Logo = () => {
  return (
    <img
      src={logo}
      alt=''
      className='logo'
    >
    </img>
  )
}

export default Logo