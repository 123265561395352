import styled from 'styled-components';

const Wrapper = styled.nav`
    display: none;
    position: absolute;
    z-index: 99; 
    top: 0;
    width: 100%;
    padding: 1rem 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.67) 0%, rgba(0, 0, 0, 0) 100%);
    .nav-links{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    a{
        color: var(--white);
    }
    .line-below-txt{
        margin-top: 4px;
        border-top: 2px solid;
        width: 0;
        transition: width 0.5s ease;
        text-align: center;
    }
    .nav-txt:hover ~ .line-below-txt{
        width: 100%;
    }
    @media (min-width: 840px) {
        display: block;
    }
`

export default Wrapper