import styled from 'styled-components';

const Wrapper = styled.div`
        position: relative;
        max-height: 100%;
    .portfolio-img-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }
    .portfolio-img-container a{
        padding-top: 1rem;
        color: black; 
        font-weight: bold;
    }
    .portfolio-img{
        max-width: 100%;
        max-height: 80vh;
        height: 100%; 
        width: 100%;
        object-fit: cover;
    }
    .img-container-box {
        height: 100%;
    }
    h4{
        width: 100%;
        position: absolute;
        bottom: 0;
        margin: 0;
        padding: 3rem 0;
        text-align: center;
        color: var(--white);
        background: linear-gradient(360deg, #000000 0%, rgba(96, 96, 96, 0) 86.85%);
    }
    .portfolio-img:hover {
        cursor: pointer;
        opacity: 0.7;
    }
    span {
        display: flex;
        width: 100%;
        position: absolute;
        z-index: 10;
        bottom: 0;
        color: var(--white);
        justify-content: center;
        margin-bottom: 1rem;
    }
    span img {
        filter: invert(100%) sepia(1%) saturate(1616%) hue-rotate(4deg) brightness(112%) contrast(95%);
        margin-left: 1rem;
        height: 1rem;
        width: 1.5rem;
    }
`

export default Wrapper