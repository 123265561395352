import styled from "styled-components";

const Wrapper = styled.div` 
    max-width: 30rem;
    background-color: var(--bodyBackground);
    box-shadow: 0px 4px 16px rgba(120, 120, 120, 0.25);
    padding: 2.5rem;
    text-align: center;
    margin: 0 1rem;
    width: 100%;
    a {
        margin-top: 2rem;
    }
`

export default Wrapper