import React from 'react'
import Wrapper from '../wrappers/TermsAndPrivacy'
import { Banner } from '../components'

const TermsAndPrivacy = () => {
  return (
    <Wrapper>
        <Banner 
            txt='REGULAMIN'
        />
        <section className='main-content'>
            <h3>POLITYKA PRYWATNOŚCI</h3>
            <p>
              Niniejsza Polityka prywatności reguluje sposób, w jaki „kuba stepek pic” gromadzi, wykorzystuje, utrzymuje i ujawnia informacje zebrane od użytkownika ze strony internetowej www.kubastepekpic.pl (zwanej dalej “Stroną”). Niniejsza polityka prywatności dotyczy strony „kuba stepek pic”. Na potrzeby lepszego odbioru Polityki Prywatności termin “użytkownik” zastąpiony został określeniem “Wy”, “Administrator” – “Ja”. Termin “RODO” oznacza Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.        
            </p>
            <p>
              W dniu 25 maja 2018 roku rozpoczęło swoje obowiązywanie Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych (w skrócie RODO). RODO pomoże chronić prywatność i sposób przechowywania danych osobowych. Zabezpieczam Wasze dane takie jak np.: imię, nazwisko, adres e-mail, numer telefonu czy informacje dotyczące Waszego ślubu czy sesji. W każdej chwili macie prawo wglądu do treści danych oraz żądania ich poprawienia lub usunięcia. Korzystanie ze Strony, przeglądanie treści na niej umieszczonych nie wymaga podania żadnych danych osobowych. Dane osobowe potrzebne są jedynie w przypadku chęci kontaktu ze mną za pośrednictwem formularza kontaktowego lub adresu e-mail umieszczonego na Stronie w celu uzyskania oferty współpracy – w tym przypadku podanie danych jest niezbędne.
            </p>
            <h3>POSTANOWIENIA OGÓLNE</h3>
            <p>
              1. Administratorem danych jest „kuba stepek pic”  z siedzibą w Zalasowej. Ochrona danych odbywa się zgodnie z wymogami powszechnie obowiązujących przepisów prawa, a ich przechowywanie ma miejsce na zabezpieczonych serwerach.
            </p>
            <p>
              2. Szanuję prawo do prywatności i dbamy o bezpieczeństwo danych.
            </p>
            <p>
              3. Przetwarzam następujące dane osobowe: imię, nazwisko, e-mail, numer telefonu, informacje dotyczące Waszego ślubu/sesji.
            </p>
            <p>
              4. Dane osobowe będą przetwarzane w celu odpowiedzi na zapytanie skierowane przy użyciu formularza kontaktowego.
            </p>
            <p>
              5. Podanie danych osobowych jest dobrowolne, jednakże brak wyrażenia zgody na przetwarzanie danych uniemożliwia świadczenie w/w usługi.
            </p>
            <p>
              6. Dane osobowe będą przechowywane przez okres 1 roku od daty wysłania zapytania
            </p>
            <p>
              7. Przysługuje Wam prawo żądania dostępu do treści danych osobowych, ich sprostowania, usunięcia oraz prawo do ograniczenia ich przetwarzania. Ponadto także prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, prawo do przenoszenia danych oraz prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych,
            </p>
            <p>
              8. Posiadacie prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.
            </p>
            <p>
              9. Dane osobowe będą przekazywane wyłącznie naszym podwykonawcom, tj. dostawcom usług informatycznych.
            </p>
            <p>
              Osobowe dane identyfikacyjne
            </p>
            <p>
              Mogę zbierać osobowe dane identyfikacyjne od Użytkowników na różne sposoby, w tym między innymi, gdy Użytkownicy odwiedzają moją Stronę, wypełniają formularz kontaktowy oraz w związku z innymi działaniami, usługami, funkcjami lub zasobami udostępnianymi na mojej stronie. Użytkownicy mogą zostać poproszeni o podanie danych takich jak imię, nazwisko, adres e-mail, numeru telefonu. Użytkownicy mogą jednak odwiedzać moją Stronę anonimowo. Będę zbierać dane osobowe od użytkowników tylko wtedy, gdy dobrowolnie prześlą mi takie informacje. Użytkownicy zawsze mogą odmówić dostarczenia osobistych danych identyfikacyjnych, z tym, że może to uniemożliwić im angażowanie się w pewne działania związane ze Stroną.
            </p>
            <p>
              Nieosobowe dane identyfikacyjne
            </p>
            <p>
              Mogę zbierać nieosobowe informacje identyfikacyjne na temat Użytkowników, ilekroć wchodzą oni w interakcję z moją Stroną. Nieosobowe dane identyfikacyjne mogą obejmować nazwę przeglądarki, rodzaj komputera i informacje techniczne na temat sposobu, w jaki Użytkownicy łączą się z moją Stroną, na przykład system operacyjny i wykorzystywani dostawcy usług internetowych oraz inne podobne informacje.
            </p>
            <p>
              Pliki Cookies przeglądarki internetowej
            </p>
            <p>
              Moja Strona może wykorzystywać pliki cookies w celu poprawy komfortu użytkowania.
            </p>
            <p>
              Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Strony i przeznaczone są do korzystania ze stron internetowych Strony. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer. Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Strony pliki cookies oraz uzyskującym do nich dostęp jest operator serwisu kuba stepek pic.
            </p>
            <p>
              Pliki cookies wykorzystywane są w celu:
            </p>
            <p>
              dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika Serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;
            </p>
            <p>
              tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Strony korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;
            </p>
            <p>
              W ramach Strony stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” oraz „stałe”. Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.
            </p>
            <p>
              W ramach Strony stosowane są następujące rodzaje plików cookies:
            </p>
            <p>
              1. „niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Strony, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Strony;
            </p>
            <p>
              2. pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Strony;
            </p>
            <p>
              3. „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Strony;
            </p>
            <p>
              4. „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;
            </p>
            <p>
              5. „reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.
            </p>
            <p>
              W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Strony mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Strony. W takim przypadku ograniczenia stosowania plików cookies mogą spowodować, że niektóre części Strony mogą nie działać poprawnie. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).
            </p>
            <p>
              Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Strony wykorzystywane mogą być również przez współpracujących z operatorem Strony reklamodawców oraz partnerów.
            </p>
            <p>
              Jak korzystam z zebranych informacji
            </p>
            <p>
              „kuba stepek pic” gromadzi i wykorzystuje dane osobowe Użytkowników do następujących celów:
            </p>
            <p>
              1. Aby poprawić moją Stronę
            </p>
            <p>
              Nieustannie dążę do udoskonalania swojej strony internetowej w oparciu o informacje i opinie, które otrzymuję od Użytkowników.
            </p>
            <p>
              2. Aby poprawić obsługę klienta
            </p>
            <p>
              Informacje Użytkowników pomagają mi skuteczniej reagować na zgłoszenia dotyczące obsługi klienta i potrzeby pomocy technicznej.
            </p>
            <p>
              3. Aby administrować treścią, promocją lub inną funkcją witryny
            </p>
            <p>
              Informacje użytkowników pozwalają mi umieszczać na Stronie treści na tematy, które wydaje mi się, że będą dla nich interesujące.
            </p>
            <p>
              4. Aby wysyłać wiadomości e-mail
            </p>
            <p>
              Adres e-mail Użytkowników, który umożliwia przetwarzanie zamówień, będzie wykorzystywany wyłącznie do wysyłania im informacji i aktualizacji dotyczących ich zamówienia. Może również służyć do odpowiadania na ich zapytania i / lub inne prośby lub pytania. Jeśli w dowolnym momencie Użytkownik chce zrezygnować z otrzymywania przyszłych wiadomości e-mail może skontaktować się ze mną za pośrednictwem mojej Strony.
            </p>
            <p>
              W jaki sposób chronię Twoje informacje
            </p>
            <p>
              Wykorzystuję odpowiednie metody gromadzenia, przechowywania i przetwarzania danych oraz środki bezpieczeństwa w celu ochrony przed nieuprawnionym dostępem, zmianą, ujawnieniem lub zniszczeniem Twoich danych osobowych, nazwy użytkownika, hasła, informacji o transakcjach i danych przechowywanych na mojej Stronie. Wrażliwa i prywatna wymiana danych między Stroną a jej Użytkownikami odbywa się za pośrednictwem bezpiecznego kanału komunikacyjnego SSL i jest szyfrowana i chroniona za pomocą podpisów cyfrowych.
            </p>
            <p>
              Udostępnianie Twoich danych osobowych
            </p>
            <p>
             NIE sprzedaję, nie handluję ani nie wypożyczam osobowych danych identyfikacyjnych użytkowników innym osobom. Mogę udostępniać ogólne zagregowane informacje demograficzne niezwiązane z żadnymi osobistymi danymi identyfikacyjnymi dotyczącymi odwiedzających Stronę i użytkowników z moimi partnerami biznesowymi, zaufanymi partnerami i reklamodawcami w celach opisanych powyżej.
            </p>
            <p>
              Witryny stron trzecich
            </p>
            <p>
              Użytkownicy mogą znaleźć na mojej Stronie reklamy lub inne treści zawierające linki do witryn i usług moich partnerów, dostawców, reklamodawców, sponsorów, licencjodawców i innych stron trzecich. Nie kontroluję zawartości ani linków, które pojawiają się w tych witrynach i nie ponoszę odpowiedzialności za praktyki stosowane przez witryny internetowe powiązane z moją Stroną. Poza tym te witryny lub usługi, w tym ich treść i linki, mogą ciągle się zmieniać. Te witryny i usługi mogą mieć swoją politykę prywatności i zasady obsługi klienta. Przeglądanie i interakcja w jakiejkolwiek innej witrynie, w tym witrynach, które mają link do mojej Strony, podlega warunkom i zasadom tej witryny.
            </p>
            <p>
              Zmiany w niniejszej Polityce prywatności
            </p>
            <p>
              „kuba stepek pic” ma prawo do aktualizowania niniejszej Polityki prywatności w dowolnym momencie. Zachęcam użytkowników do częstego sprawdzania tej strony pod kątem wszelkich zmian, aby być na bieżąco z informacjami o tym, w jaki sposób pomagam chronić gromadzone przeze mnie dane osobowe. Użytkownik przyjmuje do wiadomości i zgadza się, że jego obowiązkiem jest okresowe przeglądanie niniejszej Polityki prywatności i zapoznawanie się z jej modyfikacjami.
            </p>
            <p>
              Twoja akceptacja niniejszych warunków
            </p>
            <p>
              Korzystając z tej Strony, wyrażasz zgodę na te zasady. Jeśli nie zgadzasz się z tymi zasadami, nie korzystaj z mojej Strony. Dalsze korzystanie z Witryny po opublikowaniu zmian w tych zasadach zostanie uznane za Twoją akceptację tych zmian.
            </p>
            <p>
              Kontakt ze mną
            </p>
            <p>
              Jeśli masz jakiekolwiek pytania dotyczące niniejszej Polityki prywatności, praktyk tej Strony lub Twoich powiązań z tą witryną, skontaktuj się ze mną na: kubastepekpic@gmail.com lub skorzystaj z mojego formularza kontaktowego.
            </p>


        </section>

    </Wrapper>
  )
}

export default TermsAndPrivacy