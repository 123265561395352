import Wrapper from "../wrappers/Card"
import { NavLink } from "react-router-dom"

const Card = ({h1, props}) => {
  return (
    <Wrapper>
        <h3>{h1}</h3>
        {props.length && props.map((e, index) => {
            return(
            <p key={index}>{e}</p>
            )
        })}
        <NavLink
            to='/contact'
            className='btn btn-block'
        >
            Wybieram
        </NavLink>
    </Wrapper>
  )
}

export default Card