import React from 'react'
import {NavLink} from 'react-router-dom';
import Wrapper from '../wrappers/SmallNavbar'
import { Logo } from '../components'
import menuIcon from '../images/menu-icon.svg'

const SmallNavbar = ({handleSidebar}) => {
  return (
    <Wrapper>
        <div className='navbar-container'>
            <NavLink
                to='/'
            >
                <Logo />
            </NavLink>
            <img src={menuIcon} alt='' className='menu-icon' onClick={handleSidebar}/>
        </div>
    </Wrapper>
  )
}

export default SmallNavbar