import { NavLinks } from '../components'
import Wrapper from '../wrappers/Navbar'

const Navbar = () => {
  return (
    <Wrapper>
      <NavLinks />
    </Wrapper>
  )  
}

export default Navbar