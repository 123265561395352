import styled from "styled-components";

const Wrapper = styled.section`
    margin: 3rem 0;
    position: relative;
    .slider-container{ 
        display: flex;
        width: 100%;
        margin: 0;
        align-items: center;
    }
    .arrow {
        height: 2rem;
        margin: 0 1rem;
    }
    
    .arrow:hover {
        cursor: pointer;
    }
    .opinions-container {
        overflow: hidden;
        max-width: 100%; 
    }
    .opinions-inner {
        display: flex;            
        transition: ease 1.5s;
    }
    .quote-img {
        height: 1.5rem;
        width: 1.5rem;
    }
    .opinions-item{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        min-width: 100%;
        height: min-content;
        column-gap: 6rem;
        text-align: center;
        align-items: center;

    }
    .opinions-img img{ 
        width: 99%;
        object-fit: cover;
        object-position: right bottom;
        height: 20rem;
    }
    .opinions-txt{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    p {
        margin-top: 1rem;
        max-width: 100%;
    }
    .arrows-container {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    @media (min-width: 966px) {
        .opinions-item{
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
        }
        .opinions-img img{ 
            height: 25rem;
        }
         
        p {
            max-width: 40rem;
        }     
    }
    @media (min-width: 840px) {
        .arrow-right {
        margin-right: -3rem;
        }
        .arrow-left {
            margin-left: -3rem;
        }
    }
`

export default Wrapper