import Wrapper from "../wrappers/AboutMe"
import { NavLink } from "react-router-dom"

const AboutMe = ({h1,p1, p2, p3, img, columnReverse, link, linkTxt}) => {
  return (
    <Wrapper>
      <div className={columnReverse ? 'aboutme-container column-reverse' : 'aboutme-container'}>
        <div className="text-field"> 
            <h3>{h1}</h3> 
            <p>{p1}</p>
            <p>{p2}</p>
            <p>{p3}</p> 
            {link && <NavLink 
              to={link}
              className='btn btn-ghost'
            >
              {linkTxt}
            </NavLink>}
        </div>
        <img 
            src={img}
            alt=''
        />
      </div>
    </Wrapper>
  )
}

export default AboutMe