import styled from 'styled-components';

const Wrapper = styled.section `
    position: relative;
    height: 25rem;
    max-height: 25rem;
    background-color: #8B8B8B;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: ${({align} )=> align};
        display: flex;   
    }
    h1 {
        position: absolute;
        bottom: 2rem;
        color: white;
        width: 100%;
        text-align: center;
        font-size: 2.5rem;
    } 
    @media (min-width: 840px) {
        h1 {
            font-size: 4rem;
        }
        img {
            max-height: 25rem;
        }
    }
`

export default Wrapper;