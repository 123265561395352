import React from 'react'

const FormButtons = ({buttons, onClick, active}) => {
  return (
    <div className='btns-container'>
        {buttons.map((e) => {
            return(
            <button
                key={e.name}
                name={e.name}
                type="button"
                className={(active === e.name) ? "btn btn-ghost active" : "btn btn-ghost"}
                onClick={onClick} 
            >
                {e.txt}
          </button>
          )
        })}
    </div>
  )
}

export default FormButtons