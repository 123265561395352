import { useState, useRef } from "react";
import Wrapper from "../wrappers/Contact";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Banner, FormButtons, Footer } from '../components';
import bannerContact from '../images/banner-contact.webp'
import emailjs from '@emailjs/browser';


const Contact = () => {
  const [ sessionType, setSessionType ] = useState('');
  const [ sessionPackage, setSessionPackage ] = useState('');
  const [sendingTxt, setSendingTxt] = useState('')
  const [showInfo, setShowInfo] = useState(false)
  const [success, setSucces] = useState(false)
  const form = useRef();

  const serviceID = 'service_bb6bgsa'
  const templateID = 'template_09jk006'
  const publicKey = '8zYtZ4BdwLZeHpqRC'


  const sessionButtons = [
    {name: 'Fotografia ślubna', txt: 'Fotografia ślubna'},
    {name: 'Sesja miłosna', txt: 'Sesja miłosna'},
    {name: 'Sesja rodzinna', txt: 'Sesja rodzinna'},
    {name: 'Inne', txt: 'Inne'},
  ]
  const packageButtons = [
    {name: 'Standard', txt: 'Standard'},
    {name: 'Premium', txt: 'Premium'},
    {name: 'Duo', txt: 'Duo'},
  ]

  const onClickSession = (e) => {
    const sessionType = e.target.name
    setSessionType(sessionType)
  }
  const onClickPackage = (e) => {
    const sessionPackage = e.target.name
    setSessionPackage(sessionPackage) 
  }
  const checkSessionType = () => {
    if(sessionType === 'Sesja miłosna' || sessionType === 'Sesja rodzinna'){
      return true
    }
    return false
  }
  const sendMail = () => {
    emailjs.sendForm(serviceID, templateID, form.current, publicKey)
      .then((result) => {
          setSendingTxt('E-mail wysłany pomyślnie!')
          setSucces(true)
          setShowInfo(true)
      }, (error) => {
          console.log(error.text);
          setSendingTxt('Coś poszło nie tak :(')
          setSucces(false)
          setShowInfo(true)
      });
  }
    

  return (
    <Wrapper success={success}>
      <Banner 
        txt='POROZMAWIAJMY'
        img={bannerContact}
      />
      <div className="contact-info">
        <p>Jeśli moje zdjęcia zrobiły na Was wrażanie i chcecie zapytać o wolny termin oraz poznać dokładniejszą ofertę możecie zrobić to za pomocą formularza. Opowiedzcie o swoich wyobrażeniach. Chętnie poznam wasze inspiracje i pomysły, chcę żeby wszystko wyglądało tak jak sobie wymarzyliście. Jeżeli macie dodatkowe pytania i z przyjemnością również spotkam się z Wami, aby omówić resztę szczegółów.</p>
      </div>
      <div className="form-container">
        <h3>ZOSTAŃMY W KONTAKCIE</h3>
        {showInfo && <p className="sending-info">{sendingTxt}</p>}
        <Formik
          initialValues={{ 
            email: '', 
            customerName: '', 
            phone: '', 
            termsAndPrivacy: false, 
            sessionType: '', 
            sessionInfo: '',
            placeOfCeremony: '',
            placeOfParty: '',
            numberOfGuests: '',
            dateOfCeremony: '',
            weddingInfo: '',
            sessionPackage: '',

          }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Pole wymagane';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Nieprawidłowy adres e-mail';
            } if (!values.customerName) {
              errors.customerName = 'Pole wymagane'
            } if (!values.termsAndPrivacy) {
              errors.termsAndPrivacy = 'Pole wymagane'
            } if (checkSessionType() && !sessionPackage) {
              errors.sessionPackage = 'Pole wymagane'
            } if (!sessionType) {
              errors.sessionType = 'Pole wymagane'
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            sendMail()
            setSubmitting(false)
            resetForm()
          }}
        >
          {({ isSubmitting }) => (
            <Form ref={form} className="form-basic">
              <p>Imię i nazwisko*</p>
              <Field type="text" name="customerName" />
              <ErrorMessage name="customerName" component="small" />
              <p>Adres e-mail*</p>
              <Field type="email" name="email" />
              <ErrorMessage name="email" component="small" />
              <p>Telefon kontaktowy (opcjonalnie)</p>
              <Field type="phone" name="phone" />
              <ErrorMessage name="phone" component="small" />
              <p>Interesuje mnie*</p>
              <FormButtons
                buttons={sessionButtons} 
                onClick={onClickSession}
                active={sessionType}
              />
              <ErrorMessage name="sessionType" component="small" />
              <Field type='text' className="hide" name='sessionType' value={sessionType}/>
              {(sessionType === 'Fotografia ślubna') && 
                <div className="wedding-container">
                  <div className="wedding-places">
                    <div>
                      <p>Miejsce ceremonii</p>
                      <Field type='text' name='placeOfCeremony'/>
                    </div>
                    <div>
                      <p>Miejsce przyjęcia</p>
                      <Field type='text' name='placeOfParty'/>
                    </div>
                  </div>
                  <div className="wedding-info">
                    <div>
                      <p>Data uroczystości</p>
                      <Field type='text' name='numberOfGuests' placeholder='DD-MM-RRRR'/>
                    </div>
                    <div>
                      <p>Liczba gości</p>
                      <Field type='text' name='dateOfCeremony'/>
                    </div>
                  </div>
                </div>
              }
              {checkSessionType() && 
                <div>
                  <p>Pakiet*</p>
                  <FormButtons 
                    buttons={sessionType === 'Sesja miłosna' ? packageButtons.slice(0, 2) : packageButtons}
                    onClick={onClickPackage}
                    active={sessionPackage}
                  />
                  <ErrorMessage name="sessionPackage" component="small" />
                  <Field type='text' className="hide" name='sessionPackage' value={sessionPackage}/>
                </div>
              }
              
              <p>Opisz swoje plany i marzenia odnośnie sesji</p>
              <Field type='text-area' name='sessionInfo'/>

              <div className="terms-container">
                <Field type="checkbox" name="termsAndPrivacy" />
                <p>Zapoznałem/am się i akceptuję Politykę Prywatności*</p>
              </div>
              <ErrorMessage name="termsAndPrivacy" component="small" />
              <button type="submit" className="btn btn-submit" disabled={isSubmitting}>
                Wyślij
              </button>
              
            </Form>
          )}        
        </Formik>
      </div>
      <Footer />
    </Wrapper>
  )
}

export default Contact