import Wrapper from "../wrappers/Portfolio"
import { Banner, PortfolioNavbar, Footer, ContactRedirect } from '../components'
import bannerPortfolioWedding from '../images/banner-portfolio-wedding.webp'
import contactRedirect from '../images/contact-redirect-5.webp'
import weddingImages from "../images/portfolio/wedding"

const PortfolioWedding = () => {

  return (
    <>
      <Wrapper>
      <Banner 
        txt='PORTFOLIO'
        img={bannerPortfolioWedding} 
        align='bottom'
      />
      <PortfolioNavbar />
      <section className="main-content">
        <h3>FOTOGRAFIA ŚLUBNA</h3>
        <p>Jestem wdzięczny za to, że obdarzacie mnie swoim zaufaniem, abym dla Was zrobił zdjęcia z wesela. W tym dniu powstają najpiękniejsze pamiątki przepełnione miłością i radością. Poniżej możecie znaleźć moje ulubione kadry. Uwielbiam dobierać styl do charakteru uroczystości. Zawsze dbam o to, by zdjęcia ślubne były ze sobą spójne i razem opowiadały jedną miłosną historię. Decydując się na wybór zdjęć do porfolio pozwoliłem sobie na różnorodność, mieszając moje ulubione kadry z różnych dni. Mam nadziję, że spośród wszystkich opublikowanych zdjęć ślubnych znajdziesz coś dla siebie.</p>
        <div className="pictures">
          {weddingImages && weddingImages.map((e, index) => {
            const setRow = index % 4
            return (
              <img key={index} src={e} alt='' className={`row-${setRow}`}/>
            )
          })}
        </div>
      </section>
      </Wrapper>
      <ContactRedirect 
        img={contactRedirect}
      />
      <Footer />
    </>
  )
}

export default PortfolioWedding