import styled from 'styled-components';

const Wrapper = styled.section`

    .main-view {
        width: 100vw;
        height: 70vh;
        background-color: #8B8B8B;
    }
   .main-img-container {
        display: flex;
        overflow-x: scroll;
    }
    .main-img{
        min-width: 100vw;
        height: 100vh;
        object-fit: cover;
    }
    .about-me{
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 9rem;
        max-width: 100%;
        margin: 3rem 5%;
        align-items: flex-start;
    }

    .about-txt {
        text-align: top;
        text-align: justify;
        text-justify: inter-word;
    }
    .portraits-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 3rem;
        max-width: 100%;
    }
    .portrait-img{
        max-width: 100%;
    }
    .to-top{
        padding-bottom: 2rem;
    }
    .to-bottom{
        padding-top: 2rem;
    }
    .portfolio h3{
        text-align: center;
    }
    .portfolio-container{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        row-gap: 1rem;
        column-gap: 1rem;
        max-width: 100%;
        margin: 3rem 5%;
        position: relative; 
        text-align: center;
    }
    .quote{
        margin: 8rem 8rem;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1.2rem;
    } 
    .quote img {
        height: 1.5rem;
    }
    .quote p{
        margin-top: 2rem;
        font-style: italic;
        max-width: 100vw;
        width: 80vw;
    }
    .offer-container{
        position: relative;
        display: flex;
        justify-content: center;
    }
    .offer-container img {
        width: 100%;
        height: 90vh;
        object-fit: cover;
        object-position: bottom;
    }
    .offer-box{
        position: absolute;
        bottom: 2rem;
        padding: 2rem 5rem;
        margin: 0 1rem;
        text-align: center; 
        max-width: 25rem;
        background-color: var(--white);
    }
    .offer-box .btn{
        margin-top: 1.5rem;
    }

    .description-container{
        max-width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1rem;
        column-gap: 4rem;
        margin: 3rem 5%;
    }
    .description-container img {
        max-width: 100%;
        object-fit: cover;
    }
    .description p {
        text-align: justify;
        text-justify: inter-word;
    }
    .txt-margin {
        margin-top: 1rem;
    }
    .opinions-content{
        margin: 0 5%;
    }
    @media (min-width: 996px) {
        .about-me {
            grid-template-columns: 1fr 1.5fr;
        }
        .portfolio-container{
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr;
        }
        .description-container{
            grid-template-columns: 1fr 2fr;
        }
        .offer-box{
            bottom: 4rem;
            right: 8rem;
        }
        .offer-container img{
            max-height: 45rem;
            object-position: center;
        }
        .about-me{
            margin: 3rem 10%;
        }
        .portfolio-container{ 
            margin: 3rem 10%;
        }
        .description-container{
            margin: 3rem 10%;
        }
        .opinions-content {
            margin: 0 10%;
        }
        .main-view {
            height: 90vh;
        }   
    }
    @media (min-width: 1460px) {
        .main-view {
            height: 100vh;
        }   
    }
`

export default Wrapper 