import styled from "styled-components";

const Wrapper = styled.section`
    text-align: center;
    .main-content {
        margin: 5rem 12% 5rem 12%;
    }
    .pictures {
        width: 100%;
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 3rem;
    }
    .pictures img {
        width: 100%; 
        height: 100%;
        /*max-height: 90vh;*/
        object-fit: cover;
    }
    
    .row-0 {
        grid-column: span 3;
    }
    p {
        max-width: none;
    }
    @media (max-width: 992px) {
        .pictures{
            grid-template-columns: 1fr;
        }
        .row-0 {
            grid-column: auto;
        }
        .main-content {
            margin: 5rem 5% 5rem 5%;
        }
    }
`

export default Wrapper