import {NavLink} from 'react-router-dom'
import Wrapper from "../wrappers/Landing"
import main1 from '../images/main-1.webp'
import main2 from '../images/main-2.webp'
import main3 from '../images/main-3.webp'
import kubaSmall1 from '../images/kuba-small-1.webp'
import kubaSmall from '../images/kuba-small.webp'
import portfolioNavImg1 from '../images/portfolio-nav-img-1.webp'
import portfolioNavImg2 from '../images/portfolio-nav-img-2.webp'
import portfolioNavImg3 from '../images/portfolio-nav-img-3.webp'
import offerNav from '../images/offer-nav.webp'
import kubaSmall2 from '../images/kuba-small-2.webp'
import contactRedirect from '../images/contact-redirect-1.webp'
import quoteMark from '../images/quote-mark.svg'
import { PortfolioNavImg, Opinions, ContactRedirect, Footer, Carousel } from "../components"

const Landing = () => {
   const slides = [main1, main2, main3]

  return (
    <Wrapper>
      <section className="main-view">
        <div className="main-img-container">
          <Carousel slides={slides}/>
        </div>
      </section>
      <section className="about-me">
        <div className="about-txt">
          <h3>KUBA STEPEK PIC</h3>
          <p>
            Mówią, że duszę artysty poznasz po tym, co tworzy. 
            Ja tworzę moją małą galerię sztuki na podstawie Waszych emocji i historii ludzi, 
            z którymi jest mi dane współpracować. Kocham wykorzystywać swoje pomysły na 
            zachowanie pięknych i wyjątkowych momentów pełnych wzruszeń i uśmiechów. 
          </p>
          <p>
            Mam na imię Kuba i zapraszam Cię do mojego świata fotografii.
          </p>
        </div> 
        <div className="portraits-container">
          <img
            className="portrait-img to-bottom"
            src={kubaSmall}
            alt=''
          />
          <img
            className="portrait-img to-top"
            src={kubaSmall1}
            alt=''
          /> 
        </div>
      </section>
      <section className="portfolio">
        <h3>PORTFOLIO</h3>
        <div className="portfolio-container">
          <PortfolioNavImg 
            img={portfolioNavImg1}
            txt={'FOTOGRAFIA ŚLUBNA'}
            path='/portfolio/wedding'
          />
          <PortfolioNavImg 
            img={portfolioNavImg2}
            txt={'SESJE MIŁOSNE'}
            path='/portfolio/love' 
          />
          <PortfolioNavImg 
            img={portfolioNavImg3}
            txt={'SESJE RODZINNE'}
            path='/portfolio/family'
          />
        </div>
      </section>
      <section className="quote">
        <div>
          <img src={quoteMark} alt=''/>
          <p>Zdjęcie to wyrażanie wrażeń. Jeżeli piękno nie będzie istnieć w nas samych, to jak w ogóle będziemy mogli je zauważyć?</p>
          <p>Ernst Haas</p>
        </div>
      </section> 
      <section className="offer-container">
        <img 
          src={offerNav}
          alt=''
        />
        <div className="offer-box">
          <h2>OFERTA</h2>
          <p>W zakładce oferta znajdziesz szczegóły dotyczące fotografii ślubnej (w tym reportaży i sesji ślubnej), sesji zakochanych oraz sesji rodzinnych.</p>
          <NavLink
            to='offer'
            className='btn btn-block'
          > 
            Zobacz ofertę
          </NavLink>
        </div>
      </section>
      <div className='opinions-content'>
        <Opinions/> 
      </div>
      <section className="description-container">
        <img 
          src={kubaSmall2}
          alt=''
        />
        <div className="description">
          <p>Profesjonalny fotograf z małopolski.</p>
          <p className="txt-margin">Podczas mojej pracy jako fotograf spotykam wielu wspaniałych ludzi, odwiedzam wyjątkowe miejsca i uczestniczę w intymnych i ważnych momentach w życiu. Miałem przyjemność wykonywać sesje miłosne i narzeczeńskie, brzuszkowe, narodzinowe, sesje i reportaże ślubne a także uwieczniać inne ważne wydarzenia.</p>
          <p className="txt-margin">Pochodzę z Zalasowej koło Tarnowa jednak jako fotograf mam okazję pracować nie tylko w Tarnowie, ale także w okolicznych miejscowościach. Fotografowałem już w takich miastach jak Tarnów, Kraków, Nowy Sącz, Tuchów, Zalasowa, Zakliczyn, Brzesko, Bochnia, Ryglice.</p>
          <p className="txt-margin">Jeśli więc szukasz fotografa na w Tarnowie, Krakowie lub okolicach to dobrze trafiłeś! Zobacz ofertę i skorzystaj z formularza kontaktowego.</p>
          <p className="txt-margin">Do zobaczenia! Kuba Stepek - fotografia małopolska</p>
          <small className="txt-margin">PS. Choć najczęściej pracuję na terenie małopolski, to tak naprawdę odległości nie mają znaczenia. Jeśli mieszkacie dalej i chcecie, bym był Waszym fotografem – również zapraszam do kontaktu.</small>
        </div>
      </section>
      <ContactRedirect 
        img={contactRedirect}
      />
      <Footer />
    </Wrapper>
  )
}

export default Landing