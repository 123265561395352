import Logo from "../components/Logo"

const links = [
    {id: 1, text: 'PORTFOLIO', path: '/portfolio/wedding'},
    {id: 2, text: 'OFERTA', path: '/offer'},
    {id: 3, text: '', path: '/', icon: <Logo />}, 
    {id: 4, text: 'O MNIE', path: '/about'},
    {id: 5, text: 'KONTAKT', path: '/contact'},
] 

export default links