import m1 from './m-1.webp'
import m2 from './m-2.webp'
import m3 from './m-3.webp'
import m4 from './m-4.webp'
import m5 from './m-5.webp'
import m6 from './m-6.webp'
import m7 from './m-7.webp'
import m8 from './m-8.webp'
import m9 from './m-9.webp'
import m10 from './m-10.webp'
import m11 from './m-11.webp'
import m12 from './m-12.webp'
import m13 from './m-13.webp'
import m14 from './m-14.webp'
import m15 from './m-15.webp'
import m16 from './m-16.webp'
import m17 from './m-17.webp'
import m18 from './m-18.webp'
import m19 from './m-19.webp'
import m20 from './m-20.webp'
import m21 from './m-21.webp'
import m22 from './m-22.webp'
import m23 from './m-23.webp'
import m24 from './m-24.webp'
import m25 from './m-25.webp'
import m26 from './m-26.webp'
import m27 from './m-27.webp'
import m28 from './m-28.webp'
import m29 from './m-29.webp'
import m30 from './m-30.webp'
import m31 from './m-31.webp'
import m32 from './m-32.webp'
import m33 from './m-33.webp'
import m34 from './m-34.webp'
import m35 from './m-35.webp'
import m36 from './m-36.webp'
import m37 from './m-37.webp'
import m38 from './m-38.webp'
import m39 from './m-39.webp'
import m40 from './m-40.webp'
import m41 from './m-41.webp'
import m42 from './m-42.webp'
import m43 from './m-43.webp'
import m44 from './m-44.webp'
import m45 from './m-45.webp'
import m46 from './m-46.webp'
import m47 from './m-47.webp'
import m48 from './m-48.webp'
import m49 from './m-49.webp'
import m50 from './m-50.webp'
import m51 from './m-51.webp'
import m52 from './m-52.webp'
import m53 from './m-53.webp'
import m54 from './m-54.webp'
import m55 from './m-55.webp'
import m56 from './m-56.webp'
import m57 from './m-57.webp'
import m58 from './m-58.webp'
import m59 from './m-59.webp'
import m60 from './m-60.webp' 
import m61 from './m-61.webp'

const loveImages = [m1, m2, m3, m4, m5, m6, m7, m8, m9, m10,
    m11, m12, m13, m14, m15, m16, m17, m18, m19, m20, m23, m22, m21, m24,
    m25, m26, m27, m33, m29, m30, m31, m32, m28, m37, m39, m36, m34, m38,
    m35, m40, m41, m42, m43, m44, m45, m46, m47, m48, m49, m50, m51, m52,
    m53, m54, m55, m56, m57, m58, m59, m60, m61]

export default loveImages